export const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

export function emptyRows(page, rowsPerPage, arrayLength) {
  return page ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}

export function descendingComparator(a, b, orderBy) {
  if (a[orderBy] === null) {
    return 1;
  }
  if (b[orderBy] === null) {
    return -1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function applyFilter({ inputData, comparator, filters }) {
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filters && Object.keys(filters).length > 0 && inputData.length > 0) {
    inputData = inputData.filter((row) =>
      Object.keys(filters).every((key) => {
        if (filters[key] === null || filters[key] === undefined || filters[key] === '') {
          return true;
        }

        if (typeof filters[key] === 'string') {
          return row[key]?.match(new RegExp(filters[key], 'i'));
        }

        if (typeof filters[key] === 'number') {
          return row[key] === filters[key];
        }

        if (Array.isArray(filters[key])) {
          return filters[key].includes(row[key]);
        }

        return true;
      })
    );
  }

  console.log(filters);

  return inputData;
}
