/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// mui
import { alpha, Box, Button, Card, CardHeader, Chip, IconButton, Stack, Typography, useTheme } from '@mui/material';

// components
import { Table, TableProvider, useTableContext } from '../../../../components/table';
import Modal from '../../../../components/Dialog';
import LatexFormula from '../../../../components/latex-formula';
import Iconify from '../../../../components/iconify';
import Label from '../../../../components/label';

import { formatDuration } from '../../../../utils/formatTime';
import { hasSolution } from '../../../../utils/solution';

function Questions({ questions = [], teacher = false, sat = false, tableWrapper, title }) {
  const theme = useTheme();
  const { loadLocalData } = useTableContext();
  const [popupShow, setPopupShow] = useState(false);
  const [popupRow, setPopupRow] = useState(false);

  useEffect(() => {
    loadLocalData(questions);
  }, [questions]);

  const onQuestionContentClick = (row) => {
    setPopupShow(true);
    setPopupRow({ ...row, type: 'question' });
  };

  const onSolutionClick = (row) => () => {
    setPopupShow(true);
    setPopupRow({ ...row, type: 'solution' });
  };

  const handlePopupClose = () => {
    setPopupShow(false);
    setPopupRow(null);
  };

  const columns = [
    {
      id: 'question',
      label: 'Question',
      disableSort: true,
      onClick: onQuestionContentClick,
      sx: { cursor: 'zoom-in', whiteSpace: 'break-spaces' },
      renderCell: ({ picture, formula, textQuestion, topic, subject, level, previousMistake }, _, idx) => {
        const difficulty = ['L1', 'L2', 'EASY']?.includes(level)
          ? 'easy'
          : ['L3', 'MEDIUM']?.includes(level)
          ? 'medium'
          : 'hard';

        const color =
          difficulty === 'adaptive'
            ? 'violet'
            : difficulty === 'easy'
            ? 'success'
            : difficulty === 'medium'
            ? 'warning'
            : 'error';
        return (
          <Box sx={{ pb: 2 }}>
            {teacher && (
              <Stack sx={{ display: 'flex', flexDirection: 'row', mb: 2, gap: 1 }}>
                <Chip label={`${idx + 1}.`} size="small" />
                {level && (
                  <Label color={color} startIcon={<Iconify icon="eva:bar-chart-outline" />}>
                    {level}
                  </Label>
                )}
                {topic && <Chip label={topic} size="small" />}
                <Chip label={subject} size="small" />
                {previousMistake && (
                  <Label color={'info'} startIcon={<Iconify icon="eva:repeat-outline" color="info" />}>
                    Previous Mistake
                  </Label>
                )}
              </Stack>
            )}
            {picture ? (
              <img style={{ height: '100%', maxHeight: 100 }} src={picture} alt="" />
            ) : formula ? (
              <LatexFormula formula={formula} />
            ) : (
              textQuestion
            )}
          </Box>
        );
      },
    },
    {
      id: 'correct',
      label: 'Answer',
      renderCell: ({ userAnswer }) => <strong>{userAnswer || 'N/A'}</strong>,
      sx: ({ correct }) => ({ color: correct ? 'green' : 'red' }),
    },
    {
      id: 'answer',
      label: 'Correct Answer',
    },
    {
      id: 'time',
      label: 'Time',
      renderCell: ({ time }) => formatDuration(time, 1),
      sx: ({ time, expectedTime }) => ({ color: time < expectedTime ? 'green' : 'red' }),
    },
    {
      id: 'solution',
      label: 'Solution',
      disableSort: true,
      renderCell: (row) =>
        !hasSolution(row.solution) ? (
          <IconButton disabled>
            <Iconify icon="eva:bulb-outline" color="text.secondary" />
          </IconButton>
        ) : (
          <IconButton onClick={onSolutionClick(row)}>
            <Iconify icon="eva:bulb-outline" color="primary.main" />
          </IconButton>
        ),
    },
  ]
    .filter((x) => (sat ? x.id !== 'answer' && x.id !== 'solution' : x))
    .filter((x) => (teacher ? x : x.id !== 'answer'));

  const rowActions = [
    {
      label: 'Open in new tab',
      icon: 'eva:diagonal-arrow-right-up-outline',
      onClick: (row) => {
        window.open(`/teacher/questions/${row.questionId}`, '_blank');
      },
      showInCell: true,
    },
  ];

  const rowStyle = (row) => ({
    background: !row.correct ? alpha(theme.palette.error.lighter, 0.2) : alpha(theme.palette.success.lighter, 0.1),
  });

  const TableWrapper = tableWrapper || Card;

  return (
    <div style={{ position: 'relative' }}>
      <TableWrapper>
        <Table
          title={title}
          columns={columns}
          hover
          rowActions={teacher && rowActions}
          hideMenuButton
          hidePagination
          rowStyle={rowStyle}
        />
      </TableWrapper>

      <Stack sx={{ padding: 3 }} direction={'row'} justifyContent={'space-between'}>
        <Modal open={popupShow} handleClose={handlePopupClose}>
          <CardHeader
            title={popupRow?.type === 'question' ? 'Question' : 'Solution'}
            action={
              <Button
                color="inherit"
                onClick={handlePopupClose}
                startIcon={<Iconify icon="eva:close-outline" color="inherit" />}
                variant="contained"
              >
                Close
              </Button>
            }
          />

          {popupRow?.type === 'question' && (
            <>
              {popupRow?.picture ? (
                <img
                  src={popupRow.picture}
                  alt={'Could not load the question. Try to re-add the picture'}
                  style={{ maxHeight: 600, margin: '15px auto' }}
                  aria-hidden="true"
                />
              ) : (
                <Stack sx={{ p: 2, alignItems: 'center', minHeight: 300, justifyContent: 'center' }}>
                  <LatexFormula formula={popupRow?.formula} />

                  <Typography fontSize={24} letterSpacing={1} sx={{ whiteSpace: 'pre-line' }}>
                    {popupRow?.textQuestion}
                  </Typography>
                </Stack>
              )}
            </>
          )}
          {popupRow?.type === 'solution' && (
            <>
              {popupRow?.solution?.type === 'image' && popupRow?.solution?.image ? (
                <img
                  src={popupRow?.solution?.image}
                  alt={'Could not load the solution. Try to reload the page'}
                  style={{ maxHeight: 600, margin: '15px auto' }}
                  aria-hidden="true"
                />
              ) : (
                <Stack sx={{ p: 2, alignItems: 'center', minHeight: 300, justifyContent: 'center' }}>
                  <Typography fontSize={24} letterSpacing={1} sx={{ whiteSpace: 'pre-line' }}>
                    {popupRow?.solution.text}
                  </Typography>
                </Stack>
              )}
            </>
          )}
        </Modal>
      </Stack>
    </div>
  );
}

export default function QuestionsWrapper(props) {
  return (
    <TableProvider>
      <Questions {...props} />
    </TableProvider>
  );
}

Questions.propTypes = {
  questions: PropTypes.array,
  teacher: PropTypes.bool,
  sat: PropTypes.bool,
  tableWrapper: PropTypes.elementType,
  title: PropTypes.string,
};
