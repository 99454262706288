/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// @mui
import { Box, Button, Card, CardHeader, Grow, Stack, Typography } from '@mui/material';

// components
import { Table, TableProvider } from '../../../components/table';
import Dialog from '../../../components/Dialog';
import Scrollbar from '../../../components/scrollbar';
import Iconify from '../../../components/iconify';

import Questions from '../test/practice/Questions';

import { formatDuration } from '../../../utils/formatTime';
import { removeNumbers } from '../../../utils/strings';

import Label from '../../../components/label';

// ----------------------------------------------------------------------

const PracticeHistoryTableComponent = ({ variant }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const columns = [
    {
      id: 'testName',
      label: 'Name',
      disableSort: true,
      renderCell: (row) => {
        if (variant === 'student' && row.sat) {
          return row.satNumber ? `Diagnostic SAT # ${row.satNumber}` : 'Diagnostic SAT';
        }
        const difficulty = row.difficulty || 'adaptive';
        const color =
          difficulty === 'adaptive'
            ? 'violet'
            : difficulty === 'easy'
            ? 'success'
            : difficulty === 'medium'
            ? 'warning'
            : 'error';
        return (
          <Box>
            <Typography variant="body2">{row.sat ? row.testName : removeNumbers(row.testName)}</Typography>
            <Label
              color={color}
              sx={{ fontSize: 11, mb: 0.5, p: 0.5 }}
              startIcon={<Iconify icon={'eva:bar-chart-outline'} width={16} />}
            >
              {`${row.difficulty || 'adaptive'}`}
            </Label>
          </Box>
        );
      },
    },
    {
      id: 'sat',
      label: 'Type',
      renderCell: (row) => <Label color={row.sat ? 'warning' : 'primary'}>{row.sat ? 'SAT' : 'Subject'}</Label>,
    },
    {
      id: 'report.avgAccuracy',
      label: 'Score',
      sx: { minWidth: 100 },
      renderCell: (row) => (
        <>
          {row.sat ? (
            <Box>
              <Typography variant="body2">
                {row?.report?.estimatedSATScoreByExpectedTime || 200}{' '}
                <Typography variant="caption" color="text.secondary" component={'span'}>
                  ({row.report?.correctQuestions || 0} / {row?.report?.questions || 0})
                </Typography>
              </Typography>
            </Box>
          ) : row.report ? (
            <Box>
              <Typography variant="body2">
                {row?.report?.avgAccuracy}%{' '}
                <Typography variant="caption" color="text.secondary" component={'span'}>
                  ({row.report?.correctQuestions || 0} / {row?.report?.questions || 0})
                </Typography>
              </Typography>
            </Box>
          ) : (
            <Typography variant="body2" color="text.secondary">
              N/A
            </Typography>
          )}
        </>
      ),
    },
    {
      id: 'report.totalTime',
      label: 'Time',
      renderCell: (row) => (
        <>
          {row.report ? (
            <Box>
              <Typography variant="body2">
                {formatDuration(row?.report?.totalTime || 0)}/{formatDuration(row?.report?.expectedTime)}
              </Typography>
            </Box>
          ) : (
            <Typography variant="body2" color="text.secondary">
              N/A
            </Typography>
          )}
        </>
      ),
    },
    { id: 'updatedAt', label: 'Date', date: true },
  ];

  const rowActions = [
    {
      label: 'View',
      icon: 'eva:eye-outline',
      onClick: (row) => {
        setSelectedRow(row);
      },
      showInCell: true,
      hidden: (row) => row.status === 'assigned',
    },
  ];

  return (
    <>
      <Grow in timeout={1600}>
        <Card>
          <CardHeader title="Practice History" sx={{ mb: 2 }} />
          <Table
            columns={columns}
            renderCell={null}
            rowActions={rowActions}
            hideMenuButton
            rowsPerPageOptions={[5, 10, 50]}
          />
        </Card>
      </Grow>

      <Dialog open={!!selectedRow} handleClose={() => setSelectedRow(null)} maxWidth="md" fullScreen>
        <Box>
          <CardHeader
            title={
              variant === 'student' && selectedRow?.sat
                ? selectedRow?.satNumber
                  ? `Diagnostic SAT # ${selectedRow.satNumber}`
                  : 'Diagnostic SAT'
                : removeNumbers(selectedRow?.testName)
            }
            action={
              <Button
                color="inherit"
                onClick={() => setSelectedRow(null)}
                startIcon={<Iconify icon="eva:close-outline" color="inherit" />}
                variant="contained"
              >
                Close
              </Button>
            }
          />

          <Stack mt={3} gap={3}>
            <Scrollbar sx={{ maxHeight: '90vh' }}>
              <Questions questions={selectedRow?.answers || []} teacher={variant !== 'student'} />
            </Scrollbar>
          </Stack>
        </Box>
      </Dialog>
    </>
  );
};

export default function PracticeHistoryTable({ fetchUrl, variant }) {
  return (
    <TableProvider
      fetchUrl={fetchUrl}
      defaultSorting={{ order: 'desc', orderBy: 'updatedAt' }}
      defaultPagination={{
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 50],
      }}
    >
      <PracticeHistoryTableComponent variant={variant} />
    </TableProvider>
  );
}

PracticeHistoryTableComponent.propTypes = {
  variant: PropTypes.oneOf(['student', 'teacher']),
};

PracticeHistoryTable.propTypes = {
  fetchUrl: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['student', 'teacher']).isRequired,
};
