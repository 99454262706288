// component
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify';

// import { Roles } from '../../../constants/roles';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = (user) => [
  // {
  //   title: 'dashboard',
  //   path: '/',
  //   icon: icon('ic_analytics'),
  //   section: 'main',
  // },
  // {
  //   title: 'SAT',
  //   path: '/sat',
  //   icon: <Iconify icon="eva:file-text-fill" width={25} />,
  //   hidden: !user.sat,
  //   section: 'main',
  // },
  // {
  //   title: 'Practice',
  //   path: '/tests',
  //   icon: <Iconify icon="eva:file-text-fill" width={25} />,
  //   section: 'main',
  // },

  // v2
  {
    title: 'Practice',
    path: '/student/practice',
    icon: <Iconify icon="material-symbols:exercise-outline-sharp" width={25} />,
    section: 'sat',
  },
  {
    title: 'Statistics',
    path: '/student/dashboard',
    icon: <Iconify icon="material-symbols:query-stats" width={25} />,
    section: 'sat',
    hidden: user.type !== 'sat',
  },

  // {
  //   title: 'SAT',
  //   path: '/student/sat',
  //   icon: <Iconify icon="eva:cube-outline" width={25} />,
  //   section: 'sat',
  //   hidden: user.type !== 'sat',
  // },

  // {
  //   title: 'Instructions',
  //   path: '/student/instructions',
  //   icon: <Iconify icon="eva:navigation-outline" width={25} />,
  //   section: 'sat',
  // },
  {
    title: 'Video Lessons',
    path: '/student/lessons',
    icon: <Iconify icon="material-symbols:play-lesson-outline" width={25} />,
    section: 'sat',
  },
  {
    title: 'Settings',
    path: '/student/settings',
    icon: <Iconify icon="eva:settings-outline" width={25} />,
    section: 'sat',
  },
];

export default navConfig;
